@import 'variables';

@media (min-width: 768px) {
	.airport-select .items-list {
		right: 0;
		left: unset;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav .nav-link {
		padding: 0;
	}
	#contact-us {
		.btn {
			max-width: 256px;
		}
	}
	#search-button {
		&.price-alert-handler-checked {
			.search-button-text {
				font-size: 16px;
			}
		}
	}
}

@media (min-width: 1200px) {
	#family-type-options, #family-type-options-second {
		.room {
			display: flex;
		}
	}
}

@media (max-width: 442px) {
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
	}
	#cookie-notice {
		.description {
			font-size: 13px !important;
			line-height: 1.5 !important;
		}

		img {
			margin: 0 5px;
		}
	}
	#detail-card {
		.info {
			min-width: unset;
			width: 100%;
			.name,
			.location {
				min-width: unset;
				font-size: 16px;
			}
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {
	#cookie-notice .description,
	.cookie-confirm-btn {
		font-size: 13px;
	}
	.hotels-filter-form {
		background-color: rgba(4, 157, 217, 0.2) !important;
	}
	#newsletter-form {
		h4,
		h5 {
			font-size: 24px;
		}
	}
	.main-inputs {
		//display: unse/t !important;
		& > div {
			.input-box {
				margin: 0 !important;
			}
		}
	}
	.input-group {
		background-color: unset !important;
		border-radius: 4px;
	}
	.flex-one-desktop {
		display: unset !important;
	}
	#flight-background-pics {
		display: none;
	}
	#auth-navbar-nav .nav-link .brief {
		display: none;
	}
	.sign-up-button {
		font-family: $fontFamily, sans-serif;
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
	#search-button-container {
		margin-top: 15px;

		#search-button {
			min-height: 50px;
			font-size: 22px;
		}
	}
	.curve-mask {
		display: none;
	}

	#slogan-container {
		padding: 50px 0;

		&:before {
			display: none;
		}
	}
	.input-items {
		margin: 6px 0;

		label {
			margin: 4px 0;
		}
	}

	#filter-form {
		.inline-inputs {
			.input {
				width: 100%;
			}
		}
	}
	.input-control:not(.filled) i {
		color: #6d6d6d;
	}
	.basic-drop-down {
		margin-right: 0;

		.body {
			max-width: unset !important;
		}
	}
	#companies {
		margin-top: 25px;
		margin-bottom: 25px;
	}
	.cars-container .cars-filter-form {
		background-color: rgba(4, 157, 217, 0.2);
	}
	.timepicker-show {
		justify-content: space-between;
		border-left: 1px solid #d8d8d8;
	}
	.cars-filter-form,
	.hotels-filter-form {
		margin-top: 80px;
		padding: 12px !important;
	}
	#search-button {
		box-shadow: unset !important;
	}
	.flights-filter-form {
		margin-top: 12px;
	}

	.rtl {
		.timepicker-show {
			border-left: none;
			border-right: 1px solid #d8d8d8;
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	footer #footer-links li,
	footer p {
		font-size: 18px;
	}

	#slogan-container .body .slogan {
		font-size: 30px;
	}
	#progress-loading {
		.body {
			justify-content: flex-start;
			padding-top: 80px;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 45px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
	#compare-area #filtered-data > div:not(:first-child),
	#compare-area .bottom-info > div:not(:first-child) {
		border-left: unset;
	}

	#compare-area {
		.comp-logo {
			justify-content: center;
			align-items: center;
			position: relative;
		}

		padding: 8px 0px;
		.company-item {
			padding: 7px 10px;
			align-items: center;
			margin-bottom: 10px;
		}
		.c-checkbox-compare {
			position: relative !important;
			top: -2px !important  ;
			left: unset !important;
		}
		.location-name {
			font-size: 36px !important;
		}
		.start-compare-btn {
			display: block !important;
			border-radius: 16px;
			margin-top: 25px;
		}
		.headline {
			text-align: left;
			font-size: 24px;
			line-height: 32px;
			margin-bottom: 20px;
		}
		#filtered-data {
			align-items: flex-start;
		}
		.underscore {
			font-size: 16px !important;
		}
		.item-name {
			font-size: 12px !important;
			margin-top: 5px !important;
		}
		.item-value {
			font-size: 16px !important;
			margin-bottom: 5px !important;
		}
	}
	.ml-i {
		margin-left: 0 !important;
	}
	.ml-md-i {
		margin-left: 12px !important;
	}
	#passthru-p3 {
		.title {
			font-size: 35px;
		}
	}

	#flights-filter-form #date-to,
	#check-out-date {
		.date-picker-range {
			// right: 0;
			// &.fade-enter-done {
			// right: 0px;
			// }
		}
	}
	#mobile-input-passengers .body {
		right: 0;
		left: unset;
	}
	footer #footer-links li {
		display: block;
		border-right-width: 0px;
		margin: 16px 0;
	}
	#cookie-notice {
		padding: 6px;

		.description {
			font-size: 15px;
			line-height: 1.7;
		}

		img {
			margin: 0 5px;
		}
	}
	.rtl {
		.basic-drop-down {
			.body {
				left: 0;
			}
		}
		#input-pricing-types .body {
			right: 0;
			left: unset;
		}
		#mobile-input-passengers .body {
			right: unset;
			left: 0;
		}
	}
	#newsletter-form {
		input.form-control::placeholder {
			font-size: 14px;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
	footer {
		p {
			font-size: 14px;
		}
	}
	#main-content {
		#title {
			font-size: 24px;
		}
		#description {
			font-size: 18px;
			text-align: justify;
		}
	}
	#detail-card {
		text-align: left;
		margin-top: 15px;
		padding: 15px;

		#title {
			text-align: left;
			font-size: 20px;
		}
		.location {
			font-size: 16px;
		}
		.time,
		.price-type {
			font-size: 14px;
		}
	}
	#main-area {
		.hotels-filter-form {
			background-color: $blue !important;

			.title {
				margin-bottom: 0px;
			}
		}
	}
	.Logo {
		width: 190px;
	}
	.hotels-filter-form {
		background-color: $blue !important;
	}
	.cruises-container {
		.cruises-filter-form {
			background-color: #d0e7f6;
			margin-top: 0px;
			padding-top: 10px;
			padding-bottom: 32px;
		}
	}
	.select-box {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;

		margin-bottom: 5px;

		.selected-area {
			min-width: 100px;
			min-height: 35px;
		}
	}
	#family-type-options, #family-type-options-second {
		width: 100% !important;
	}
	.room-name-area {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: space-between;

		.remove-icon-mobile {
			display: block;
		}
	}
	#mobile-input-passengers .body {
		width: calc(200% + 30px);
	}
	.cars-filter-form,
	.hotels-filter-form {
		margin-top: 0 !important;
		border-top-left-radius: 0px !important;
		border-top-right-radius: 0px !important;
	}
	#companies {
		ul {
			li {
				width: 145px;

				div img {
					width: 120px;
				}
			}
		}
	}
	#slogan-container {
		.body {
			.slogan-container-img {
				width: 100%;
				height: auto;
			}
			.slogan {
				font-size: 25px !important;
			}
		}
	}
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
		.logo {
			width: 106px !important;
		}
		.brand-redirection {
			padding: 5px;
			margin-top: 5px;
		}
		#loading-to-partner #animation .m_scroll_arrows {
			width: 12px;
			height: 12px;
		}
	}
}

@media (min-width: 1200px) {
	.flights-companies #companies {
		margin-top: 150px !important;

		margin-bottom: 120px;
	}
	#main-area {
		min-height: 870px;
		&.rtl {
			.main-inputs {
				& > div {
					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.main-inputs {
		&.rtl > div {
			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
		& > div {
			padding: 0;

			&:first-child {
				padding-left: 15px;
			}
		}
		#search-button {
			margin-right: 15px;
		}
	}

	.airport-select {
		.items-list {
			min-width: 400px;
		}
	}
}

@media (max-width: 575px) {
	#search-button-container #search-button {
		&:hover,
		&:active {
			opacity: 1 !important;
			border: none;
		}
	}
	#family-type-options .room .name {
		font-weight: bold;
	}
	#family-type-options:before {
		right: 49.7%;
	}
	#family-type-options:after {
		right: 50%;
	}

	#family-type-options-second .room .name {
		font-weight: bold;
	}
	#family-type-option-second:before {
		right: 49.7%;
	}
	#family-type-options-second:after {
		right: 50%;
	}

	#main-area {
		.hotels-filter-form {
			background-color: rgba(4, 157, 217, 0.2) !important;
			.title {
				margin-bottom: 0px;
				//background-color: #fff;
			}
		}
	}
	#main-content {
		.item .content {
			padding: 16px;
		}
	}
	.btn-blue-border {
		min-width: 100px;
		line-height: 1.2;
	}

	.rtl {
		#room-type-basic-drop-down.basic-drop-down,
		#input-pricing-types.basic-drop-down {
			.item {
				i {
					padding-right: 0;
					margin-left: 10px;
				}
			}
			.body {
				direction: rtl;
			}
		}
	}
}
@media (max-width: 360px) {
	.Logo {
		width: 160px;
	}
	#footer-links {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		li {
			padding: 0 !important;
		}
	}
	#slogan-container {
		.body {
			padding: 0;
		}
	}
}

@media (max-width: 320px) {
	.date-picker-range {
		right: unset !important;
		left: calc(var(--picker-right-offset) / 2) !important;
	}
	#newsletter-form {
		input.form-control::placeholder {
			font-size: 12px;
		}
	}
	#mobile-input-passengers.basic-drop-down {
		.box:nth-of-type(1) {
			justify-content: flex-start;
		}
	}
	.xs-input-full {
		width: 100%;
	}
	.basic-drop-down {
		width: 100%;
		// .dropdown-input {
		// 	display: flex;
		// 	justify-content: space-between;
		// }
		.d-name {
			font-size: 13px;
		}
		.body {
			width: 100%;
			.item {
				font-size: 15px;
			}
		}
	}
}
