$midnight: #100225;
$fontFamily: var(--font-assistant);
$navLinkColor: rgba(255, 255, 255, 0.8);
$yellow: #ffe165;
$black: #333333;
$brownGrey: #9b9b9b;
$aquaBlue: #00d6e6;
$blue: #049dd9;
$blueLight: #09caed;
$blueDark: #1d2a45;
$iris: #5d5fef;
$orange: #fdab00;
